import { getLowestPriceFromProduct, parsePrice } from "../util/productUtil"
import BasketType from "../model/BasketType"
import { isRunTime } from "../util/generalUtil"
import { CheckoutFormStateType } from "../pages/checkout/CheckoutPage"
import { ProductType, VariantType } from "@social-supermarket/social-supermarket-model"

declare global {
  interface Window {
    fbq: any
  }
}

export const productView = (product: ProductType) => {
  track("ViewContent", {
    content_ids: [getFacebookId(product.productId, product.sku || "")],
    content_type: "product_group",
    value: getLowestPriceFromProduct(product),
    currency: "GBP",
  })
}

export const addToBasket = (product: ProductType, variant: VariantType, quantity: number) => {
  const id = getFacebookId(
    variant ? variant.variantId : product.productId,
    variant ? variant.sku : product.sku
  )
  track("AddToCart", {
    content_ids: [id],
    content_type: variant ? "product" : "product_group",
    value: getLowestPriceFromProduct(product),
    currency: "GBP",
  })
}

export const orderSuccess = (
  orderId: number,
  basket: BasketType,
  formState: CheckoutFormStateType
) => {
  initAdvancedTracking(
    formState?.contactInfo?.email,
    formState?.billingAddress?.firstName,
    formState?.billingAddress?.lastName
  )

  const value = parsePrice(basket.total)
  if (value > 0) {
    track("Purchase", { currency: "GBP", value })
  }
}

export const lead = () => {
  track("Lead", {})
}

const initAdvancedTracking = (email: string, firstName: string, lastName: string) => {
  if (isRunTime() && typeof window.fbq !== "undefined") {
    window.fbq("init", "167875347772702", {
      em: email,
      fn: firstName,
      ln: lastName,
    })
  }
}

const track = (event: string, object: object) => {
  if (isRunTime() && typeof window.fbq !== "undefined") {
    window.fbq("track", event, object)
  }
}

const getFacebookId = (id, sku) => {
  if (sku) {
    return `${sku}_${id}`
  } else {
    return `wc_post_id_${id}`
  }
}
