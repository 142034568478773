import * as React from "react"
import styled from "styled-components"
import { Icon, Progress } from "semantic-ui-react"
import { formatPrice } from "../../util/productUtil"

export const FREE_SHIPPING_THRESHOLD = 75

const Container = styled.div``
const ProgressContainer = styled.div`
  margin-bottom: 10px !important;
  div {
    margin-bottom: 0 !important;
  }
`
const Note = styled.div`
  font-size: 0.8em;
`

interface Props {
  subTotal: number
}

const ShippingProgress = ({ subTotal }: Props) => {
  const percent = (subTotal / FREE_SHIPPING_THRESHOLD) * 100
  return (
    <Container>
      <ProgressContainer>
        <Progress percent={percent} color="black" indicating />
      </ProgressContainer>
      {percent >= 100 ? (
        <Note>
          <Icon name="shipping fast" />
          Shipping: FREE
        </Note>
      ) : (
        <Note>
          <Icon name="shipping fast" /> Spend {formatPrice(FREE_SHIPPING_THRESHOLD - subTotal)} more
          for free shipping
        </Note>
      )}
    </Container>
  )
}

export default ShippingProgress
