exports.components = {
  "component---src-gatsby-templates-post-post-template-tsx": () => import("./../../../src/gatsby/templates/post/PostTemplate.tsx" /* webpackChunkName: "component---src-gatsby-templates-post-post-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-b-corp-gifts-branded-merch-tsx": () => import("./../../../src/pages/b-corp-gifts-branded-merch.tsx" /* webpackChunkName: "component---src-pages-b-corp-gifts-branded-merch-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-bespoke-gifts-tsx": () => import("./../../../src/pages/bespoke-gifts.tsx" /* webpackChunkName: "component---src-pages-bespoke-gifts-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-brand-social-enterprise-gift-boxes-index-tsx": () => import("./../../../src/pages/brand/social-enterprise-gift-boxes/index.tsx" /* webpackChunkName: "component---src-pages-brand-social-enterprise-gift-boxes-index-tsx" */),
  "component---src-pages-brands-tsx": () => import("./../../../src/pages/brands.tsx" /* webpackChunkName: "component---src-pages-brands-tsx" */),
  "component---src-pages-build-your-own-gift-box-tsx": () => import("./../../../src/pages/build-your-own-gift-box.tsx" /* webpackChunkName: "component---src-pages-build-your-own-gift-box-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-corporate-gifting-tsx": () => import("./../../../src/pages/corporate-gifting.tsx" /* webpackChunkName: "component---src-pages-corporate-gifting-tsx" */),
  "component---src-pages-corporate-hampers-gift-boxes-tsx": () => import("./../../../src/pages/corporate-hampers-gift-boxes.tsx" /* webpackChunkName: "component---src-pages-corporate-hampers-gift-boxes-tsx" */),
  "component---src-pages-corporate-tsx": () => import("./../../../src/pages/corporate.tsx" /* webpackChunkName: "component---src-pages-corporate-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-labels-js": () => import("./../../../src/pages/labels.js" /* webpackChunkName: "component---src-pages-labels-js" */),
  "component---src-pages-office-supplies-js": () => import("./../../../src/pages/office-supplies.js" /* webpackChunkName: "component---src-pages-office-supplies-js" */),
  "component---src-pages-order-success-tsx": () => import("./../../../src/pages/order-success.tsx" /* webpackChunkName: "component---src-pages-order-success-tsx" */),
  "component---src-pages-pwc-risk-gift-selection-tsx": () => import("./../../../src/pages/pwc-risk-gift-selection.tsx" /* webpackChunkName: "component---src-pages-pwc-risk-gift-selection-tsx" */),
  "component---src-pages-pwc-tsx": () => import("./../../../src/pages/pwc.tsx" /* webpackChunkName: "component---src-pages-pwc-tsx" */),
  "component---src-pages-refer-us-to-your-company-tsx": () => import("./../../../src/pages/refer-us-to-your-company.tsx" /* webpackChunkName: "component---src-pages-refer-us-to-your-company-tsx" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-social-enterprise-gift-boxes-tsx": () => import("./../../../src/pages/social-enterprise-gift-boxes.tsx" /* webpackChunkName: "component---src-pages-social-enterprise-gift-boxes-tsx" */),
  "component---src-pages-sustainable-branded-merchandise-tsx": () => import("./../../../src/pages/sustainable-branded-merchandise.tsx" /* webpackChunkName: "component---src-pages-sustainable-branded-merchandise-tsx" */),
  "component---src-pages-team-gifting-platform-tsx": () => import("./../../../src/pages/team-gifting-platform.tsx" /* webpackChunkName: "component---src-pages-team-gifting-platform-tsx" */),
  "component---src-templates-brand-template-js": () => import("./../../../src/templates/BrandTemplate.js" /* webpackChunkName: "component---src-templates-brand-template-js" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/CollectionTemplate.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-gift-guide-template-tsx": () => import("./../../../src/templates/GiftGuideTemplate.tsx" /* webpackChunkName: "component---src-templates-gift-guide-template-tsx" */),
  "component---src-templates-home-page-template-tsx": () => import("./../../../src/templates/HomePageTemplate.tsx" /* webpackChunkName: "component---src-templates-home-page-template-tsx" */),
  "component---src-templates-impact-template-js": () => import("./../../../src/templates/ImpactTemplate.js" /* webpackChunkName: "component---src-templates-impact-template-js" */),
  "component---src-templates-organisation-template-js": () => import("./../../../src/templates/OrganisationTemplate.js" /* webpackChunkName: "component---src-templates-organisation-template-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-category-template-tsx": () => import("./../../../src/templates/ProductCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-product-category-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

